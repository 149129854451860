import http from '@/utils/http'

// 回单管理列表
export function getBackShipmentList (p) {
  return http.get('/material/shipment/getBackShipmentList', p)
}

// 回单管理-导出列表
export function exportBackShipmentList (p) {
  return http.postBlob('/material/shipment/exportBackShipmentList', p)
}
