<template>
  <page-container>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="发货日期:">
        <el-date-picker
          v-model="sendDate"
          clearable
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="工程名称:">
        <el-input v-model.trim="searchForm.projName" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="施工单位:">
        <el-input v-model.trim="searchForm.cusName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="企业名称:">
        <el-input v-model.trim="searchForm.compName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="技术要求:">
        <el-select v-model="searchForm.technicalRequirement" clearable placeholder="请选择" >
          <el-option v-for="item in technicalRequirements" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="receiptShaJiang"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
      :footer-method="footerMethod"
      show-footer
    >
      <template #buttons>
        <el-button size="small" icon="el-icon-download" type="primary" @click="exportTable">导出</el-button>
      </template>
      <vxe-column type="seq" width="80" align="center" title="序号" fixed="left" />
      <vxe-column field="compName" title="企业名称" min-width="220" />
      <vxe-column field="taskID" title="任务单号" min-width="166" />
      <vxe-column field="scheduleID" title="发货号" min-width="100" />
      <vxe-column field="confirmTime" title="签收时间" min-width="146" />
      <vxe-column field="tankNumber" title="罐号" min-width="100" />
      <vxe-column field="cusName" title="施工单位" min-width="220" />
      <vxe-column field="projName" title="工程名称" min-width="220" />
      <vxe-column field="technicalRequirement" title="技术要求" min-width="100" />
      <vxe-column field="weight" title="净重(吨)" min-width="80" />
      <vxe-column field="confirmWeight" title="签收净重(吨)" min-width="110" />
      <vxe-column field="backCarWeight" title="回车重(吨)" min-width="100" />
      <vxe-column field="differentWeight" title="差量(吨)" min-width="80" />
      <vxe-column field="licPlate" title="车号" min-width="100" />
      <vxe-column field="driverName" title="驾驶员" min-width="80" />
      <vxe-column field="deliveryMethodName" title="提货方式" min-width="100" />
      <vxe-column field="signUserName" title="签收人" min-width="100" />
      <vxe-column field="sendUserName" title="发货人" min-width="100" />
      <vxe-column field="remark" title="备注" min-width="150" />
    </BaseTable>
  </page-container>
</template>

<script>
import {
  getBackShipmentList,
  exportBackShipmentList
} from '@/apis/prod_manage/receiptShaJiang'
import { exportToxlsx } from '@/utils/tools'
import BaseTable from '../../../components/common/BaseTable/index.vue'
export default {
  data () {
    return {
      technicalRequirements: [
        'WPM10',
        'WPM15',
        'DPM15散装',
        'WPM20',
        '抹灰PM10',
        '砌筑MM10',
        '抹灰PM15',
        'WP-G M7.5',
        'WPM5',
        'PM10+防水剂',
        '抹灰PM5',
        'WM M10',
        'WMM7.5',
        'WP-G M15',
        'DPM7.5',
        'DPM20',
        '地面SM15',
        'DPM15',
        'M15',
        'DPM10散装',
        'DMM10散装',
        'DM7',
        'DP M5',
        'DP M20'
      ],
      searchForm: {
        startCreateTime: '',
        endCreateTime: '',
        projName: '',
        technicalRequirement: '',
        compName: '',
        cusName: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 15,
        totalSize: 0
      },
      totalResult: {
        backCarWeight: 0,
        confirmWeight: 0,
        differentWeight: 0,
        weight: 0 // 净重
      },
      loading: false,
      editData: {}
    }
  },
  computed: {
    sendDate: {
      get () {
        const searchForm = this.searchForm
        if (searchForm.startCreateTime && searchForm.endCreateTime) {
          const startDate = searchForm.startCreateTime
          const endDate = searchForm.endCreateTime
          return [startDate, endDate]
        } else {
          return null
        }
      },
      set (newVal) {
        const searchForm = this.searchForm
        const [startDate, endDate] = newVal || ['', '']
        searchForm.startCreateTime = startDate
        searchForm.endCreateTime = endDate
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      const res = await getBackShipmentList(params)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.rows.map(item => {
          const status = { 1: '未审核', 2: '已审核' }
          const types = { 0: '未知', 1: '包装', 2: '散装' }
          const deliveryMethods = { 0: '未知', 1: '自提', 2: '非自提' }
          item.checkStatusName = status[item.checkStatus]
          item.typeName = types[item.type]
          item.deliveryMethodName = deliveryMethods[item.deliveryMethod]
          return item
        })
        this.tablePage.totalSize = res.data.totalSize
        this.totalResult = res.data.totalResult
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    async exportTable () {
      const searchForm = this.searchForm
      const res = await exportBackShipmentList(searchForm)
      exportToxlsx(res, '回单明细-砂浆')
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if ([
            'backCarWeight',
            'confirmWeight',
            'differentWeight',
            'weight'
          ].includes(column.property)) {
            return this.totalResult[column.property]
          }
          return null
        })
      ]
    }
  },
  components: { BaseTable }
}
</script>
